import useSWR from 'swr'

import Loading from '../components/Loading'
import Layout from '../components/Layout'
import ProductsGrid from '../components/ProductsGrid'

export default function Home() {
  const { data: packs } = useSWR('/api/packs')

  if (!packs) return <Loading />

  return (
    <Layout>
      <ProductsGrid products={packs} />
    </Layout>
  )
}